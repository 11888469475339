/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import PropTypes from "prop-types";
import { Link } from "gatsby";

export default function Breadcrumb({ items }) {
  return (
    <div>
      <Box
        as="ul"
        sx={{
          fontSize: "16px",
          listStyle: "none",
          color: "#000000",
          pl: 0,
          "& > li+li": {
            "::before": {
              content: "' » '",
              fontSize: "22px",
            },
          },
        }}
      >
        {items
          ? items.map((item) => {
              return (
                <li
                  sx={{
                    display: "inline",
                  }}
                >
                  <Link
                    sx={{ textDecoration: "none", color: item.color }}
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })
          : null}
      </Box>
    </div>
  );
}

Breadcrumb.defaultProps = {
  items: [],
};
Breadcrumb.propTypes = {
  items: PropTypes.instanceOf(Array),
};
